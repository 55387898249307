<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id，外键{user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id，外键{user.id}" />
      </a-form-model-item>
      <a-form-model-item label="资源id" prop="resourceId" >
        <a-input v-model="form.resourceId" placeholder="请输入资源id" />
      </a-form-model-item>
      <a-form-model-item label="资源内容" prop="resourceContent" >
        <editor v-model="form.resourceContent" />
      </a-form-model-item>
      <a-form-model-item label="举报原因" prop="reason" >
        <a-input v-model="form.reason" placeholder="请输入举报原因" />
      </a-form-model-item>
      <a-form-model-item label="反馈内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="反馈图片地址集合,多个英文逗号分割" prop="imgUrls" >
        <a-input v-model="form.imgUrls" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="处理状态:1-待处理，2-已处理，3-暂不处理" prop="processStatus" >
      </a-form-model-item>
      <a-form-model-item label="处理结果" prop="processResult" >
        <a-input v-model="form.processResult" placeholder="请输入处理结果" />
      </a-form-model-item>
      <a-form-model-item label="处理时间" prop="handleTime" >
        <a-date-picker style="width: 100%" v-model="form.handleTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getReport, addReport, updateReport } from '@/api/user/report'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        resourceId: null,

        resourceContent: null,

        reason: null,

        content: null,

        imgUrls: null,

        processStatus: 0,

        processResult: null,

        handleTime: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id，外键{user.id}不能为空', trigger: 'blur' }
        ],
        resourceId: [
          { required: true, message: '资源id不能为空', trigger: 'blur' }
        ],
        resourceContent: [
          { required: true, message: '资源内容不能为空', trigger: 'blur' }
        ],
        handleTime: [
          { required: true, message: '处理时间不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        resourceId: null,
        resourceContent: null,
        reason: null,
        content: null,
        imgUrls: null,
        processStatus: 0,
        processResult: null,
        handleTime: null,
        createTime: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getReport({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateReport(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addReport(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
